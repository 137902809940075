@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;1,300&family=Metal+Mania&family=Rubik+Marker+Hatch&display=swap');

@layer base {
    body {
        @apply font-[Lato];
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}


.irl-aaa  {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 400px;
    }





.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)
    )!important;
}

@media only screen and (max-width: 900px) {
    .responsivepic {
        display: block;
        width: 100%;
    }
    .haha {
        display: grid;
        display: block;
    }
}